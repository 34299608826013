<template>

        <base-material-card color="info">

            <template v-slot:heading>
            <div class="display-2 font-weight-light">
              <b>{{legalEntity.name}}</b>
            </div>

            <div class="subtitle-1 font-weight-light">
              {{legalEntity.vatNumber}}
            </div>
          </template>

    <v-container>

             <v-row v-for="(brand, index) in brands" :key="brand.id">
                <v-col cols="12">
                    <brand-loyalty :brand="brand"
                    ></brand-loyalty>
                    
                </v-col>
                <v-col cols="12">
                <hr v-if="index != brands.length -1" />
                </v-col>
            </v-row>
    </v-container>

  
    </base-material-card>

</template>

<script>
import brandLoyalty from './brandLoyalty'
export default {
    components: {
        'brand-loyalty': brandLoyalty
    },
    props: {
        legalEntity: Object,
        brands: Array
    }
}
</script>