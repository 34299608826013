<template>

    <div v-if="!brands">
        <!-- brands are loading -->
    </div>

    <div v-else>

        <div v-for="le in legalEntities" :key="le.id">
            <legal-entity v-if="getBrandsOfLegalEntity(le.id).length"
                :brands="getBrandsOfLegalEntity(le.id)" 
                :legalEntity="le" 
            />
        </div>
       

    </div>

</template>

<script>
import legalEntity from './legalEntity'
export default {
    components: {
        'legal-entity': legalEntity
    },
    data() {
        return {
            hasReadStarterInfo: false
        }
    },
    computed: {
        brands() {
            return this.$store.getters.brands
        },
        legalEntities () {
            return this.$store.getters.legalEntities
        },
        hasBrandWithActivatedLoyalty() {
            return !!this.brands.find(p => p.loyalty.activated)
        }
    },
    methods: {
        getBrandsOfLegalEntity(leId) {
            return this.brands.filter(p => p.legalEntityId == leId)
        }
    }
    
}
</script>